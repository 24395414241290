export const SETTING_REQUEST = 'SETTING_REQUEST'
export const SETTING_SUCCESS = 'SETTING_SUCCESS'
export const SETTING_FALIURE = 'SETTING_FALIURE'

export const PROFILE_DATA_REQUEST = 'PROFILE_DATA_REQUEST'
export const PROFILE_DATA_SUCCESS = 'PROFILE_DATA_SUCCESS'
export const PROFILE_DATA_FALIURE = 'PROFILE_DATA_FALIURE'

export const SMS_DATA_REQUEST = 'SMS_DATA_REQUEST'
export const SMS_DATA_SUCCESS = 'SMS_DATA_SUCCESS'
export const SMS_DATA_FALIURE = 'SMS_DATA_FALIURE'

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FALIURE = 'UPLOAD_IMAGE_FALIURE'