export const REQUEST_PIN = "REQUEST_PIN";
export const REQUEST_PIN_DONE = "REQUEST_PIN_DONE";
export const REQUEST_DISPLAY = "REQUEST_DISPLAY";
export const SCAN_DEVICES = "SCAN_DEVICES";
export const CONNECT_DEVICE = "CONNECT_DEVICE";
export const DISCONNECT_DEVICE = "DISCONNECT_DEVICE";
export const DEPOSIT_REQUEST_DATA = "DEPOSIT_REQUEST_DATA";
export const SEND_TLV_DATA = "SEND_TLV_DATA";
export const RECIEVE_RESPONSE = "RECIEVE_RESPONSE";
export const RECIEVE_RESPONSE_DONE = "RECIEVE_RESPONSE_DONE";
export const RECIEVE_DISPLAY = "RECIEVE_DISPLAY";
export const POS_INFO = "POS_INFO"
export const SUCCESS_CARD_SCAN = "SUCCESS_CARD_SCAN"
export const SUCCESS_TRANS = "SUCCESS_TRANS"

